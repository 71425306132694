import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
        {
            path: '/policies/:number',
            name: 'policies',
            component: () => import(/* webpackChunkName: "PDBase" */ '@/views/PDBase.vue'),
            children: [
                {
                    path: '/forbidden',
                    name: 'forbidden',
                    component: () => import(/* webpackChunkName: "KCErrorForbidden" */ '@imt/vue-kit-car/src/components/errors/Forbidden.vue'),
                    meta: {
                        title: 'Forbidden'
                    },
                },
            ]
        },
        {
            path: '*',
            component: () => import(/* webpackChunkName: "KCNotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
            meta: {
                title: 'Not Found'
            },
        },
    ],

    router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes,
    });

export default router;
