import utils, {axiosWithAuth, deepCase} from '@imt/vue-toolbox/src/utils';

export const authedAxios = axiosWithAuth();

export const actions = {
    async fetchPageInfo(context, { number, policyQuery = '' }) {
        await context.dispatch('fetchPolicyByFilter', { number, queryString: policyQuery});
        await context.dispatch('fetchLatestPolicyVersion');
    },
    async fetchClaims(context, {id}) {
        let response = await authedAxios.get(`/policies/${id}/claims/`);

        return deepCase(response.data.data);
    },
    async fetchPolicyByFilter(context, { number, queryString = '' }) {
        let response = await authedAxios.get(`/policies/?filter[number]=${number}&${queryString}`),
            policies = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_STATE_KEY', {
            key: 'policy',
            value: policies[0],
        });

        return policies[0];
    },
    async fetchPolicy(context, { id, queryString = '' }) {
        let response = await authedAxios.get(`/policies/${id}/?${queryString}`),
            policy = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_STATE_KEY', {
            key: 'policy',
            value: policy,
        });

        return policy;
    },
    async fetchPolicyGroup(context, { id, queryString = '' }) {
        let response = await authedAxios.get(`/policy-groups/${id}/?${queryString}`),
            policyGroup = utils.dataFormatter.deserialize(response.data);

        return policyGroup;
    },
    async fetchPolicyGroups(context) {
        let response = await authedAxios.get(`/policy-groups/`),
            policyGroups = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_STATE_KEY', {
            key: 'policyGroups',
            value: policyGroups,
        });

        return policyGroups;
    },
    async fetchLatestPolicyVersion(context) {
        const id = context.state.policy?.id;

        if (id) {
            let response = await authedAxios.get(`/policies/${id}/latest-version/`),
                latestPolicyVersion = utils.dataFormatter.deserialize(response.data);

            context.commit('SET_STATE_KEY', {
                key: 'latestPolicyVersion',
                value: latestPolicyVersion,
            });

            return latestPolicyVersion;
        }

        return {};
    },
    async pushPolicyToClient(context, { id }) {
        return await authedAxios.get(`/policies/${id}/client`);
    },
    async fetchUserById(_, id) {
        const response = await authedAxios.get(`${process.env.VUE_APP_ADMIN_URL_USERS}/api/v1/users/${id}`);

        return utils.dataFormatter.deserialize(response.data) || {};
    },
    async getBillingAccount(_, {accountNumber}) {
        const response = await authedAxios.get(`${process.env.VUE_APP_BILLING_URL}/api/v1/accounts/${accountNumber}/`);

        return utils.dataFormatter.deserialize(response.data);
    },
};

export const mutations = {
    SET_STATE_KEY(state, { key, value }) {
        state[key] = value;
    },
};

export const state = () => {
    return {
        latestPolicyVersion: {},
        policy: {},
        policyGroups: {},
    };
};

export default ({
    namespaced: true,
    actions,
    mutations,
    state: state(),
});
