import toolbox from '@imt/vue-toolbox/src/store';
import Vue from 'vue';
import Vuex from 'vuex';

import policy from '@/store/policy';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        toolbox,
        policy
    },
});
