<template>
    <RouterView />
</template>

<script>
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';

    export default {
        name: 'App',
        mixins: [
            authMixin
        ],
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"
</style>
